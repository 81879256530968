import Layout from '@components/layout';
import React from 'react';
import { Link } from 'gatsby';

export default function edtech(props) {
  return (
    <Layout {...props}>
      <div className="hero hero-2 solutions-hero">
        <div className="hero-2__background" />
        <div className="hero-2__container" />
        <div className="solutions-hero__wrapper">
          <div className="solutions-hero__pattern">
            <img src="/images/case-hero-pattern.png" alt="" />
          </div>

        <div className="solutions-hero__background">
          <h2 class="h2-label">
            <div class="h2-label__title">Experience</div>
            <div class="h2-label__seperator"></div>
          </h2>
          <img src="/images/edtech-banner.png" />
        </div>
      </div>
    </div>
  
    <section className="solutions-content">
      <div className="long-content__wrapper">
        <h2 className="solution-section__header">
          Custom Software Solutions for Education: Empower, Engage, and Evolve
        </h2>
        <p className="solution-section__description">
          As the educational landscape continues to change and evolve, technology plays a pivotal role. Our custom software solutions are purpose-built for educational institutions, enhancing learning outcomes, fostering engagement, and simplifying administrative processes. We believe in technology's potential to transform education, and our mission is to provide the tools to make that transformation a reality.
        </p>

        <h2 className="solution-section__header">
          The Power of Custom Software in Education
        </h2>
        <p className="solution-section__description">
          Custom software brings countless advantages to the realm of education. Here are some key benefits:
        </p>
        <ul>
          <li>
            <span>
              <b>Personalization:</b> Our software can provide tailored learning experiences, adapting content and pace to suit individual students' needs.
            </span>
          </li>
          <li>
            <span>
              <b>Engagement:</b> Interactive interfaces, gamified elements, and real-time feedback can significantly boost students' engagement and motivation.
            </span>
          </li>
          <li>
            <span>
              <b>Accessibility:</b> We build software that ensures education is accessible to all, regardless of geographical location or personal circumstances.
            </span>
          </li>
          <li>
            <span>
              <b>Efficiency: </b> Automating administrative tasks like scheduling, grade reporting, and attendance tracking saves educators valuable time.
            </span>
          </li>
          <li>
            <span>
              <b>Data Security: </b> We prioritize data security, providing solutions that keep student and institutional data safe and secure.
            </span>
          </li>
        </ul>

        <h2>
          Driving Innovation in Education
        </h2>

        <div className="solution-cards__group">
          <SolutionCard title="Immersive Technologies">
            We can integrate cutting-edge technologies like AR/VR to create immersive, experiential learning environments.
          </SolutionCard>
          <SolutionCard title="Data-Driven Insights">
            Our software enables efficient data collection and analysis, leading to actionable insights that can improve teaching strategies and learning outcomes.
          </SolutionCard>
          <SolutionCard title="Collaborative Learning">
            By fostering online collaboration, we make learning a more interactive and enriching experience.
          </SolutionCard>
          <SolutionCard title="Continual Learning">
            With e-learning platforms, students can access materials at any time, reinforcing continuous learning.
          </SolutionCard>
        </div>

        <h2>Our experience building custom EdTech solutions</h2>

        <div className="cases__container">
          <div className="cases__case-card">
            <div className="case-card__wrapper">
              <div className="case-card__logo">
                <img src="/images/brands/prisma-white-brand.svg" alt="" />
              </div>
              <div className="case-card__description">
                WyeWorks made Prisma Live a reality, being the engineering team who built the learning platform purpose-built for kids from scratch.
              </div>
              <Link
                to="/case-studies/prisma"
                className="case-card__link primary-link"
              >
                Read Case Study
              </Link>
            </div>
            <div className="case-card__background">
              <img
                src="/images/prisma-case-card-bg.png"
                alt="prisma case study"
              />
            </div>
          </div>
          <div className="cases__case-card">
            <div className="case-card__wrapper">
              <div className="case-card__logo">
                <img src="/images/brands/argos-white-brand.png" alt="" />
              </div>
              <div className="case-card__description">
                WyeWorks came in to revamp Argos’ educational platform
              </div>
              <Link
                to="/case-studies/argos"
                className="case-card__link primary-link"
              >
                Read Case Study
              </Link>
            </div>
            <div className="case-card__background">
              <img src="/images/argos-case-card-bg.png" alt="argos case study" />
            </div>
          </div>
        </div>

        <h2 className="solution-section__header">
          Why Choose Us
        </h2>
        <p className="solution-section__description">
          As partners in your educational journey, we offer more than just software. We offer a commitment to your institution's success because we know the challenges and opportunities unique to the education sector, and our solutions reflect that knowledge. In addition, our high quality standards allow for a reliable and user-friendly software making it easier for the application to grow with you as your needs evolve.
        <br />          <br />
          Ready to revolutionize your educational institution?
        </p>
      </div>
    </section>
  </Layout>
  );
}
  
function SolutionCard({ title, children }) {
  return (
    <div className="solution__card">
      <h3>{title}</h3>
      <p>{children}</p>
    </div>
  );
}
